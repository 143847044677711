.justify {
    text-align: justify ;
    text-justify: inter-word;
}

.center {
    text-align: center;
}

.italic {
    font-style: italic;
}

.light {
    font-weight: 300;
}

.regular {
    font-weight: 400;
}

.medium {
    font-weight: 500;
}

.semibold {
    font-weight: 600;
}

.bold {
    font-weight: 700;
}

.extrabold {
    font-weight: 800;
}

.black {
    font-weight: 900;
}

.large {
    font-size: 2.5em;
}

.big {
    font-size: 2em;
}

.biggish {
    font-size: 1.6em;
}

.text{
    font-size: 1.25em;
}

.small {
    font-size: 1em;
}

.xsmall {
    font-size: 0.8em;
}

.footnotesize {
    font-size: 0.75em;
}
