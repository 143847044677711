.red {
    color: #bf616a;
}
.orange {
    color: #d08770;
}

.yellow {
    color: #ebcb8b;
}

.green {
    color: #a3be8c;
}

.purple {
    color: #b48ead;
}