/* -- Margins -- */

.m-s {
    margin: 0.5rem;
}

.m-m {
    margin: 1rem;
}

.m-l {
    margin: 1.5rem;
}

.m-xl {
    margin: 2rem;
}

.mt-s {
    margin-top: 0.5rem;
}

.mt-m {
    margin-top: 1rem;
}

.mt-l {
    margin-top: 1.5rem;
}

.mt-xl {
    margin-top: 2rem;
}

.mb-s {
    margin-bottom: 0.5rem;
}

.mb-m {
    margin-bottom: 1rem;
}

.mb-l {
    margin-bottom: 1.5rem;
}

.mb-xl {
    margin-bottom: 2rem;
}

.mb-xxl {
    margin-bottom: 2.5rem;
}

.ml-s {
    margin-left: 0.5rem;
}

.ml-m {
    margin-left: 1rem;
}

.ml-l {
    margin-left: 1.5rem;
}

.ml-xl {
    margin-left: 2rem;
}

.mr-s {
    margin-right: 0.5rem;
}

.mr-m {
    margin-right: 1rem;
}

.mr-l {
    margin-right: 1.5rem;
}

.mr-xl {
    margin-right: 2rem;
}

/* -- Padding -- */

.p-s {
    padding: 0.5rem;
}

.p-m {
    padding: 1rem;
}

.p-l {
    padding: 1.5rem;
}

.p-xl {
    padding: 2rem;
}

.pt-s {
    padding-top: 0.5rem;
}

.pt-m {
    padding-top: 1rem;
}

.pt-l {
    padding-top: 1.5rem;
}

.pt-xl {
    padding-top: 2rem;
}

.pb-s {
    padding-bottom: 0.5rem;
}

.pb-m {
    padding-bottom: 1rem;
}

.pb-l {
    padding-bottom: 1.5rem;
}

.pb-xl {
    padding-bottom: 2rem;
}

.pb-xxl {
    padding-bottom: 2.5rem;
}

.pl-s {
    padding-left: 0.5rem;
}

.pl-m {
    padding-left: 1rem;
}

.pl-l {
    padding-left: 1.5rem;
}

.pl-xl {
    padding-left: 2rem;
}

.pr-s {
    padding-right: 0.5rem;
}

.pr-m {
    padding-right: 1rem;
}

.pr-l {
    padding-right: 1.5rem;
}

.pr-xl {
    padding-right: 2rem;
}

.top {
    top: -8rem;
}